// TODO: ต้องกลับมาสร้างตัว Error ใหม่ เพราะตอนนี้มันไม่สามารถแสดงข้อมูล Error ที่ถูกต้องได้

import axios from '@axios'
import store from '@/store'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorHandler from '@/libs/ErrorHandler'
import { service, factories, models } from 'powerbi-client'

const token = store.getters['heroAiAuthentications/sanctumToken']
const axiosConfig = AxiosConfig.getJsonWithToken(token)

export default {
  async generateEmbedToken() {
    try {
      const url = `${process.env.VUE_APP_HEROAI_API}powerbi/generate-embed-token`

      const { data } = await axios(
        url, {
          method: 'post',
          headers: axiosConfig.headers,
        },
      )

      const reports = data.data.reports.reduce((list, item) => ({
        ...list,
        [item.name]: item.id,
      }), {})

      return {
        embedToken: data.data.token,
        expiration: data.data.expiration,
        reports,
      }
    } catch (error) {
      return new ErrorHandler(error)
    }
  },

  async generateEmbedReport(reportName, reportContainer) {
    try {
      const { embedToken, reports } = await this.generateEmbedToken()

      const permissions = models.Permissions.All
      const reportId = reports[reportName]
      const embedUrl = `https://app.powerbi.com/reportEmbed?${reportId}`

      const config = {
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: embedToken,
        embedUrl,
        id: reportId,
        permissions,
      }

      this.powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory)
      return this.powerbi.embed(reportContainer, config)
    } catch (error) {
      return new ErrorHandler(error)
    }
  },
}
